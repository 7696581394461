import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import ItemService from '../../components/item-service/item.service';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TopBar from '../../components/top-bar/top-bar';
import { Container, Section, Wrapper } from '../../styles/servicios/redes-electricas.elements';

const ServicePage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Otros servicios"}) {
        title
        images {
          gatsbyImageData
        }
      }
    }
  `);
  const { images } = data.contentfulPages;
  return (
    <Layout>
      <main>
        <TopBar title="OTROS SERVICIOS" imageName="nosotros.jpg" previous="seguridad" />

        <Section>
          <Wrapper>
            <Container className="gap">
              <ItemService title="Obra civil" description={<p>Ofrecemos servicios integrales de ingeniería y construcción, trabajando en el sector en actividades de construcción, ampliación y remodelación de edificaciones residenciales, comerciales e industriales. <br /> <br /> Adecuaciones locativas,  superficies en drywall y perforación de muros.</p>} image={getImage(images[0]) as IGatsbyImageData} altImage="Planos arquitectonicos" />

              <ItemService title="Mobiliaria ( muebles y divisiones para oficina )" description="Toda empresa requiere mantenimiento continuo y muebles en todas sus dependencias y áreas de trabajo. Nosotros hemos dispuesto permanentemente un servicio de atención inmediata para el sector del mueble de oficina y bancario, brindando distintos tipos de muebles y divisiones para su zona de trabajo." image={getImage(images[1]) as IGatsbyImageData} altImage="Iluminacion en oficinas" />

              <ItemService title="Redes contra incendio y aire acondicionado" description="Ofrecemos sistemas completos de protección contra incendios para prevenir o controlar un incendio asegurando la integridad de su personal, junto con sistemas industriales de de aire acondicionado que permiten lograr las condiciones frescas, cómodas e ideales para algunos procesos de producción." image={getImage(images[2]) as IGatsbyImageData} altImage="Ductos de aire acondicionado" />
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default ServicePage;

export const Head: HeadFC = () => <Seo title="Otros servicios" />;
